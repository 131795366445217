import React, { useState, useEffect, useMemo } from "react";

import {
  Button,
  Input,
  FormLabel,
  FormControl,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Switch,
} from "@chakra-ui/react";

import { axiosPost } from "../../utils/api";

const createContentApi =
  process.env.REACT_APP_API_HOST + process.env.REACT_APP_CREATE_KOL;

const updateContentApi =
  process.env.REACT_APP_API_HOST + process.env.REACT_APP_UPDATE_KOL;

const KolRegisterDialog = ({ isOpen, onOpen, onClose, fetchData, data }) => {
  const cancelRef = React.useRef();

  const [name, setName] = useState("");
  const [redirectLink, setRedirectLink] = useState("");
  const [videoUrl, setVideoUrl] = useState();
  const [isLive, setIsLive] = useState();
  const toast = useToast();

  useEffect(() => {
    if (data) {
      // const imageName = data?.thumbnailUrl
      //   ? data.thumbnailUrl.split("/").pop()
      //   : "";
      // setFileName(imageName);
      setName(data?.nickname);
      setVideoUrl(data?.cdnM3u8Link);
      setRedirectLink(data?.redirectLink);
      // setIsLive(data?.isLive);
    }
  }, [data]);

  const clickUpdateButton = async () => {
    if (!name) {
      toast({
        title: "Name Is Require",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    if (!videoUrl) {
      toast({
        title: "Link Video Is Require",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    if (!redirectLink) {
      toast({
        title: "Redirect link Is Require",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    let contentData = {
      cdnM3u8Link: videoUrl,
      redirectLink,
      nickname: name,
    };
    if (data) {
      contentData = {
        ...contentData,
        kol_id: data?._id,
        // isLive
      };
    }
    try {
      const response = await axiosPost(
        data ? updateContentApi : createContentApi,
        contentData
      );
      if (response?.data?.code == 0) {
        toast({
          title: data ? "Update Kol Successfully" : "Create Kol Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        fetchData();
        onClose();
        setName("");
        setVideoUrl("");
      }
    } catch (error) {
      toast({
        title:
          error?.response?.data?.errors?.errors[0]?.msg ||
          error?.response?.data?.msg ||
          (data ? "Update Kol Fail" : "Create Kol Fail"),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onCloseDialog = () => {
    if (!data) {
      setName("");
      setVideoUrl("");
      setRedirectLink("");
    }
    // setIsLive(data.isLive);

    onClose();
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCloseDialog}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            {!data ? "Create new kol" : "Update the kol"}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
           
            <FormControl mt={2}>
              <FormLabel>Nick name</FormLabel>
              <Input
                type="text"
                placeholder="Nick name"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </FormControl>
            <FormControl mt={2}>
              <FormLabel>Redirect link</FormLabel>
              <Input
                type="text"
                placeholder="Redirect link"
                value={redirectLink}
                onChange={(event) => setRedirectLink(event.target.value)}
              />
            </FormControl>
            <FormControl mt={2}>
              <FormLabel>CDN M3u8 link</FormLabel>
              <Input
                type="text"
                placeholder="Cdn M3u8 link"
                value={videoUrl}
                onChange={(event) => setVideoUrl(event.target.value)}
              />
            </FormControl>
            {/* <FormControl mt={2}>
              <FormLabel>Trực tiếp</FormLabel>
              <Switch
                size="md"
                isChecked={isLive}
                onChange={(e) => setIsLive(e.target.checked)}
              />
            </FormControl> */}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCloseDialog}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => clickUpdateButton()}
            >
              Save
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default KolRegisterDialog;
