import {
  Flex,
  Text,
  Collapse,
  Box,
  useDisclosure,
  useToast,
  Spacer,
  Icon,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { BsXCircleFill } from "react-icons/bs";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import React, { useState, useEffect } from "react";

import { axiosPost, axiosGet } from "utils/api";
import AddKolIntoLinkDialog from "./AddKolIntoLinkDialog";
import EditLinkDialog from "./EditLinkDialog";
import useAxios from "axios-hooks";
import { API_ROUTES, ROOT_API } from "utils/constant";
const deleteLinkApi =
  process.env.REACT_APP_API_HOST + process.env.REACT_APP_CDN_DELETE_LINK;

function ExpandedRow(props) {
  const { data, refetch, dataKols, isMember } = props;
  const toast = useToast();
  const {
    isOpen: isOpenAddKol,
    onOpen: onOpenAddKol,
    onClose: onCloseAddKol,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [expandedRows, setExpandedRows] = useState([]);

  const [selectedLink, setSelectedLink] = useState();
  const [datas, setDatas] = useState();
  const handleAddKolIntoLink = (link) => {
    onOpenAddKol();
    setSelectedLink(link);
  };

  const handleDeleteLink = async (e, link) => {
    e.stopPropagation();
    if (window.confirm("Are you sure to remove this link")) {
      try {
        const dataDelete = {
          linkId: link._id,
        };
        const response = await axiosPost(
          deleteLinkApi + "/" + data?.fixture?.id,
          dataDelete
        );
        if (response?.data?.code == 0) {
          toast({
            title: "Delete Link Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          refetch();
        }
      } catch (error) {
        console.log(error);
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Delete Link Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };
  useEffect(() => {
    if (isMember) { 
      fetchMember();
    } else {
      fetchMes();
    }
  }, [isMember]);
  const fetchMes = async () => {
    const response = await axiosGet(
      ROOT_API + API_ROUTES.GET_MESSAGES + data?.slug
    );
    if (response?.data?.code == 0) {
      const resData = response.data.data;
      const id = resData?.slug;
      setDatas(resData);
    }
  };
  const fetchMember = async () => {
    console.log(data)
    const response = await axiosGet(
      ROOT_API + API_ROUTES.GET_DETAIL_MEMBER + data?.username
    );
    if (response?.data?.code == 0) {
      const resData = response.data.data;
      const id = resData?.slug;
      setDatas(resData);
    }
  };

  const toggleRow = async (memberId) => {
    if (expandedRows.includes(memberId)) {
      setExpandedRows(expandedRows.filter((rowId) => rowId !== memberId));
    } else {
      setExpandedRows([...expandedRows, memberId]);
    }
  };
  const getLinkName = (linkUrl) => {
    const result = linkUrl?.split("/")?.pop();
    return result;
  };
  console.log(expandedRows);
  console.log(datas);
  return (
    <>
      <Collapse in={true}>
        {datas?.length &&
          datas.map((i) => (
            <Flex>
              <Box colSpan={8}>{i.content}</Box>{" "}
              <IconButton
                icon={
                  expandedRows?.includes(i?.user?.username) ? (
                    <ChevronDownIcon />
                  ) : (
                    <ChevronUpIcon />
                  )
                }
                onClick={() => toggleRow(i?.user?.username)}
                aria-label={
                  expandedRows?.includes(i?.user?.username)
                    ? "Hide details"
                    : "Show details"
                }
              />
              {expandedRows.includes(i?.user?.username) && (
                <Flex>
                  <Box colSpan={8}>
                    <ExpandedRow data={i.user} dataKols={dataKols} isMember={true} />
                  </Box>
                </Flex>
              )}
            </Flex>
          ))}
      </Collapse>
      {/* <AddKolIntoLinkDialog
        isOpen={isOpenAddKol}
        onOpen={onOpenAddKol}
        onClose={onCloseAddKol}
        fetchData={refetch}
        dataCurrentMatch={data}
        dataKols={dataKols}
        link={selectedLink}
      /> */}
      {/* <EditLinkDialog
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        fetchData={refetch}
        data={selectedLink}
        matchId={data.matchId}
      /> */}
    </>
  );
}

export default ExpandedRow;
