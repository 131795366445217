import React, { useState, useEffect, useCallback } from "react";

import {
  Select,
  Button,
  Input,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  useColorModeValue,
  Flex,
  Text,
} from "@chakra-ui/react";

import { axiosPost } from "../../utils/api";
import { API_ROUTES, ROOT_API, SITE_URLS } from "utils/constant";
import { CopyIcon } from "@chakra-ui/icons";
import useAxios from "axios-hooks";

const LinkMatchSeo = (props) => {
  const { isOpen, onOpen, onClose, match, cmt } = props;
  const [link, setLink] = useState("");
  const cancelRef = React.useRef();
  const toast = useToast();
  const textColor = useColorModeValue("gray.500", "white");

  const [{ loading: setFBLoading }, setFBLinkPostApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.SET_FB_LINK,
    },
    { manual: true }
  );

  const [{ response, loading: checkLoading }, checkLinkPostApi] = useAxios(
    {
      method: "post",
      url: ROOT_API + API_ROUTES.CHECK_LINK,
    },
    { manual: true }
  );

  const handleCopyUrl = (matchUrl) => {
    navigator.clipboard.writeText(matchUrl);
  };
  const renderLink = useCallback(() => {
    const siteUrls = SITE_URLS;
    const data = siteUrls.map((i) => [`${i}tran-dau/${match.slug}`]);
    return data.map((i, id) => {
      if (cmt) {
        const link = `${i}?commentator=${cmt}`;
        return (
          <Flex key={id} justifyContent={"space-between"} alignItems="center">
            <Text>- {link}</Text>
            <Button px="2" bg="transparent" onClick={() => handleCopyUrl(link)}>
              <CopyIcon />
            </Button>
          </Flex>
        );
      }
      return (
        <Flex key={id} justifyContent={"space-between"} alignItems="center">
          <Text>{`${i}`}</Text>
          <Button px="2" bg="transparent" onClick={() => handleCopyUrl(i)}>
            <CopyIcon />
          </Button>
        </Flex>
      );
    });
  }, [cmt]);

  const handleSuccess = () => {
    toast({
      title: `Save Successfully`,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  const handleError = (error) => {
    toast({
      title:
        error?.response?.data?.errors?.[0]?.msg ||
        error?.response?.data?.msg ||
        `Save Post Fail`,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  const handleSaveLinkFacebook = () => {
    const payload = {
      matchId: match?.matchId,
      facebookLink: link,
      commentator: link ? cmt : null,
    };
    setFBLinkPostApi({
      data: payload,
    })
      .then(() => {
        handleSuccess();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  useEffect(() => {
    if (isOpen) {
      checkLinkPostApi({
        data: {
          commentator: cmt,
          matchId: match?.matchId,
        },
      })
        .then((res) => {
          if (res?.data?.data?.facebookLink) {
            setLink(res?.data?.data?.facebookLink);
          }
        })
        .catch((error) => {
          handleError(error);
        });
    }
  }, [cmt, match?.matchId]);

  const handleClose = () => {
    setLink("");
    onClose();
  }
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={handleClose}
        isOpen={isOpen}
        isCentered
        size="4xl"
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            {`Link Trận Đấu: ${match?.home_name || match?.localteam_title} vs
            ${match?.away_name || match?.visitorteam_title}`}
            {cmt && <Text>{`BLV: ${cmt}`}</Text>}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {match?.isHot && (
              <Flex mb={5}>
                <Input
                  type="text"
                  placeholder="Link facebook"
                  value={link}
                  onChange={(event) => setLink(event.target.value)}
                />
                <Button
                  colorScheme="blue"
                  ml={3}
                  isLoading={setFBLoading}
                  onClick={handleSaveLinkFacebook}
                >
                  Lưu
                </Button>
                <Button
                  colorScheme="blue"
                  ml={3}
                  disabled={!link}
                  onClick={() => {
                    navigator.clipboard.writeText(link);
                    toast({
                      title: `Copy link Successfully`,
                      status: "success",
                      duration: 9000,
                      isClosable: true,
                    });
                  }}
                >
                  Sao chép link
                </Button>
              </Flex>
            )}
            <Flex flexDirection="column" rowGap={2}>
              {renderLink()}
            </Flex>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={handleClose}>
              Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default LinkMatchSeo;
