import {
  Avatar,
  Badge,
  Button,
  Checkbox,
  Flex,
  Link,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { axiosPost } from "utils/api";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import PreviewVideo from "./PreviewVideo";
import UploadThumbnail from "./UploadThumbnail";
import KolRegisterDialog from "./KolRegisterDialog";
import { MdCancel } from "react-icons/md";
import { SiTicktick } from "react-icons/si";
const deleteContentApi =
  process.env.REACT_APP_API_HOST + process.env.REACT_APP_DELETE_KOL;
const activeContentApi =
  process.env.REACT_APP_API_HOST + process.env.REACT_APP_KOL_STATUS;

function KolRow(props) {
  const { data, isLast, fetchData } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const toast = useToast();
  const {
    isOpen: isRegisterOpen,
    onOpen: onRegisterOpen,
    onClose: onRegisterClose,
  } = useDisclosure();
  const {
    isOpen: isUploadOpen,
    onOpen: onUploadOpen,
    onClose: onUploadClose,
  } = useDisclosure();
  const {
    isOpen: isPreviewVideoOpen,
    onOpen: onPreviewVideoOpen,
    onClose: onPreviewVideoClose,
  } = useDisclosure();

  function handleRowClick() {
    onRegisterOpen();
  }

  async function handleDeleteClick() {
    if (window.confirm("Are you sure to delete the kol?")) {
      const contentData = {
        id: data?._id,
      };
      try {
        const response = await axiosPost(deleteContentApi, contentData);
        if (response?.data?.code == 0) {
          toast({
            title: "Delete Kol Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          fetchData();
        }
      } catch (error) {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Delete Kol Fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  }
  async function handleChangeStatus(id) {
    if (window.confirm("Are you sure to change status the kol?")) {
      const contentData = {
        id,
      };
      try {
        const response = await axiosPost(activeContentApi, contentData);
        if (response?.data?.code == 0) {
          toast({
            title: "Update status kol successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          fetchData();
        }
      } catch (error) {
        toast({
          title:
            error?.response?.data?.errors?.errors[0]?.msg ||
            error?.response?.data?.msg ||
            "Update status kol fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  }

  // const getFileName = (path) => {
  //   if (path) {
  //     const fileName = path?.split("/")?.pop();
  //     return (
  //       <Button variant="unstyled" onClick={() => onPreviewVideoOpen()}>
  //         {fileName}
  //       </Button>
  //     );
  //   }
  //   return "";
  // };
  return (
    <>
      <Tr>
        <Td
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
          minWidth={150}
        >
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {data?.nickname}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {data?.rtmp}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {data?.cdnM3u8Link}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {data?.redirectLink}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Button
              px="2"
              bg="transparent"
              // variant="no-effects"
              onClick={() => handleChangeStatus(data?._id)}
              cursor={"pointer"}
              mr={4}
            >
              <Text fontSize="md" color={textColor} fontWeight="bold">
                {data?.isLive ? (
                  <SiTicktick color="green" />
                ) : (
                  <MdCancel color="red" />
                )}
              </Text>
            </Button>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Button
            px="2"
            bg="transparent"
            // variant="no-effects"
            onClick={handleRowClick}
            mr={4}
          >
            <EditIcon />
          </Button>
          <Button
            px="2"
            bg="transparent"
            // variant="no-effects"
            onClick={handleDeleteClick}
          >
            <DeleteIcon />
          </Button>
        </Td>
      </Tr>
      <KolRegisterDialog
        isOpen={isRegisterOpen}
        onOpen={onRegisterOpen}
        onClose={onRegisterClose}
        fetchData={fetchData}
        data={data}
      />
      <UploadThumbnail
        isOpen={isUploadOpen}
        onOpen={onUploadOpen}
        onClose={onUploadClose}
        fetchData={fetchData}
        data={data}
      />
      {data?.cdnM3u8Link && (
        <PreviewVideo
          isOpen={isPreviewVideoOpen}
          onOpen={onPreviewVideoOpen}
          onClose={onPreviewVideoClose}
          videoUrl={data.cdnM3u8Link}
        />
      )}
    </>
  );
}

export default KolRow;
