// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
// Custom components
import useAxios from "axios-hooks";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { useState, useMemo, useEffect, Fragment, useRef } from "react";
import Loading from "components/Layout/Loading";
import { TablePagination } from "@trendmicro/react-paginations";
import "@trendmicro/react-paginations/dist/react-paginations.css";
import FilterMatch from "../FilterMatch/FilterMatch";
import { ROOT_API } from "utils/constant";
import { API_ROUTES } from "utils/constant";
import { initialFilter } from "utils/constant";
import WeekFilter from "../WeekFilter/WeekFilter";
import { downloadFile, today } from "utils/helpers";
import LeagueFilter from "components/LeagueFilter/LeagueFilter";
import CreateMatchDialog from "./components/CreateMatchDialog";
import Row from "./components/Row";
import { SearchIcon } from "@chakra-ui/icons";
import DatePicker from "components/DatePicker/DatePicker";
import moment from "moment";
import { Select } from "chakra-react-select";

// const matchApi =
//   process.env.REACT_APP_API_HOST + process.env.REACT_APP_MATCH_ALL_MATCHES;
const cdnLinkApi =
  process.env.REACT_APP_API_HOST + process.env.REACT_APP_CDN_LINKS;
function TrackingShare() {
  const bgColor = useColorModeValue("blue.200", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", { textColor });
  const [searchValue, setSearchValue] = useState("");
  const refSearchButton = useRef(null);
  const toast = useToast();
  const [filterDate, setFilterDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [filter, setFilter] = useState({
    ...initialFilter,
  });
  const [listCommentator, setListCommentator] = useState([]);
  const {
    isOpen: isOpenCreate,
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
  } = useDisclosure();
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: ROOT_API + API_ROUTES.TRACKING_SHARE,
      params: filter,
    },
    {
      useCache: false,
    }
  );
  const [{ loading: exportLoading }, exportApi] = useAxios(
    {
      method: "get",
      url: ROOT_API + API_ROUTES.TRACKING_SHARE,
      responseType: "arraybuffer",
    },
    { manual: true }
  );
  const [{ data: listCommentatorData }] = useAxios(
    {
      url: ROOT_API + API_ROUTES.GET_COMMENTATOR,
      params: filter,
    },
    {
      useCache: false,
    }
  );

  const optionsListCommentator = useMemo(() => {
    return (
      listCommentatorData?.data?.map((itemCommentator) => ({
        value: itemCommentator.username,
        label: itemCommentator.name,
      })) || []
    );
  }, [listCommentatorData]);

  const onChangeDate = (type) => (date) => {
    setFilterDate((prev) => ({
      ...prev,
      ...(type === "startDate" && { endDate: null }),
      [type]: date,
    }));
  };

  const handleExport = () => {
    exportApi({ params: { isExport: "true", ...filter } })
      .then((response) => {
        downloadFile(response?.data, "tracking-share");
        toast({
          title: "Export tracking share successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title:
            error?.response?.data?.errors?.errors?.[0]?.msg ||
            error?.response?.data?.msg ||
            "Export tracking share fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  console.log("listCommentator", listCommentator);

  return (
    <>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Flex justifyContent={"space-between"}>
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Tracking Share
              </Text>
              <Button
                colorScheme="blue"
                onClick={handleExport}
                isLoading={exportLoading}
              >
                Export
              </Button>
            </Flex>
          </CardHeader>
          <CardBody>
            <Flex gap={3} mb={3} flexDirection={"column"} alignItems={"start"}>
              <Flex gap={3} w={"full"} flexDirection={"column"}>
                <InputGroup>
                  <InputRightElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                  </InputRightElement>
                  <Input
                    type="text"
                    variant="outline"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    onKeyUp={(event) => {
                      if (event.key === "Enter" && event?.target?.value) {
                        const searchBtn = refSearchButton?.current;
                        if (searchBtn !== null) {
                          searchBtn.click();
                        }
                      }
                    }}
                  />
                </InputGroup>
                <Flex
                  flexWrap="wrap"
                  alignItems={"end"}
                  marginTop={1}
                  gap={"20px"}
                >
                  <FormControl
                    display="flex"
                    flexDirection={"column"}
                    width={{ base: "full", sm: "300px" }}
                    maxW="300px"
                  >
                    <FormLabel
                      m="0"
                      fontSize="sm"
                      color={textColor}
                      fontWeight="bold"
                    >
                      Start Date
                    </FormLabel>
                    <DatePicker
                      selectedDate={filterDate.startDate}
                      onChange={(date) => onChangeDate("startDate")(date)}
                    />
                  </FormControl>
                  <FormControl
                    display="flex"
                    flexDirection={"column"}
                    width={{ base: "full", sm: "300px" }}
                    maxW="300px"
                  >
                    <FormLabel
                      m="0"
                      fontSize="sm"
                      color={textColor}
                      fontWeight="bold"
                    >
                      End Date
                    </FormLabel>
                    <DatePicker
                      selectedDate={filterDate.endDate}
                      minDate={filterDate.startDate}
                      onChange={(date) => onChangeDate("endDate")(date)}
                    />
                  </FormControl>
                  <FormControl
                    display="flex"
                    flexDirection={"column"}
                    width={{ base: "full", sm: "300px" }}
                    maxW="300px"
                  >
                    <FormLabel
                      m="0"
                      fontSize="sm"
                      color={textColor}
                      fontWeight="bold"
                    >
                      BLV
                    </FormLabel>
                    <Select
                      isClearable
                      placeholder="Choose"
                      menuShouldBlockScroll
                      value={listCommentator || null}
                      options={optionsListCommentator}
                      onChange={(e) => {
                        setListCommentator(e);
                        setFilter((prev) => ({
                          ...prev,
                          commentator: e ? e.value : null,
                        }));
                      }}
                      onMenuClose={() => {
                        if (!listCommentator) {
                          setListCommentator(null);
                        }
                      }}
                    />
                  </FormControl>
                </Flex>
              </Flex>
              <Flex alignItems={"end"} gap={3}>
                <Button
                  ref={refSearchButton}
                  disabled={
                    !searchValue && !filterDate.endDate && !filterDate.startDate
                  }
                  colorScheme="blue"
                  onClick={() => {
                    setFilter({
                      ...filter,
                      ...filterDate,
                      searchKeyword: searchValue,
                      pageIndex: 0,
                    });
                  }}
                >
                  Search
                </Button>
                <Button
                  onClick={() => {
                    setSearchValue("");
                    setFilter({
                      ...initialFilter,
                      searchKeyword: "",
                      commentator: null,
                    });
                    setFilterDate({ startDate: null, endDate: null });
                  }}
                >
                  Reset
                </Button>
              </Flex>
            </Flex>
            {loading ? (
              <Box py="30px">
                <Loading />
              </Box>
            ) : (
              <>
                <Stack overflow={"auto"}>
                  <Table variant="simple" color={textColor}>
                    <Tbody>
                      {data?.data?.length
                        ? data.data.map((row, index, arr) => {
                            return (
                              <Fragment key={index}>
                                <Tr>
                                  <Td
                                    borderColor={borderColor}
                                    borderBottom={
                                      index === arr.length - 1 ? "none" : null
                                    }
                                    // colSpan={6}
                                    bg={borderColor}
                                  >
                                    <Text
                                      fontSize="md"
                                      color={textColor}
                                      fontWeight="bold"
                                    >
                                      {`Link: ${row?.slug}`}
                                    </Text>
                                    <Text
                                      fontSize="md"
                                      color={textColor}
                                      fontWeight="bold"
                                    >
                                      {`Thời gian: ${moment(row?.time).format(
                                        "YYYY-MM-DD HH:mm:ss"
                                      )}`}
                                    </Text>
                                    <Text
                                      fontSize="md"
                                      color={textColor}
                                      fontWeight="bold"
                                    >
                                      {`Tổng lượt share: ${row?.total}`}
                                    </Text>
                                    {row?._id?.commentator && (
                                      <Text
                                        fontSize="md"
                                        color={textColor}
                                        fontWeight="bold"
                                      >
                                        {`BLV: ${row?._id?.commentator}`}
                                      </Text>
                                    )}
                                  </Td>
                                </Tr>
                                <Tr>
                                  <Td px={0} py={0}>
                                    <Table>
                                      <Thead>
                                        <Tr
                                          py="1rem"
                                          pl="0px"
                                          color={textColor}
                                          bg={bgColor}
                                        >
                                          <Th
                                            borderColor={borderColor}
                                            color={textColor}
                                          >
                                            Tên đăng nhập
                                          </Th>
                                          <Th
                                            borderColor={borderColor}
                                            color={textColor}
                                          >
                                            Người chia sẻ
                                          </Th>
                                          <Th
                                            borderColor={borderColor}
                                            color={textColor}
                                          >
                                            Số lượt chia sẻ
                                          </Th>
                                          <Th
                                            borderColor={borderColor}
                                            color={textColor}
                                          >
                                            Thời gian share đầu tiên
                                          </Th>
                                          <Th
                                            borderColor={borderColor}
                                            color={textColor}
                                          >
                                            Thời gian share cuối cùng
                                          </Th>
                                        </Tr>
                                      </Thead>
                                      {row?.members?.length
                                        ? row.members.map(
                                            (row_child, _index, _arr) => (
                                              <Tbody key={row_child._id}>
                                                <Row
                                                  data={row_child}
                                                  fetchData={refetch}
                                                  isLast={
                                                    _index === _arr.length - 1
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              </Tbody>
                                            )
                                          )
                                        : ""}
                                    </Table>
                                  </Td>
                                </Tr>
                              </Fragment>
                            );
                          })
                        : ""}
                    </Tbody>
                  </Table>
                </Stack>

                <Flex justifyContent="flex-end">
                  <TablePagination
                    type="full"
                    page={filter.pageIndex + 1}
                    pageLength={filter.pageSize}
                    totalRecords={data?.pagination?.count}
                    onPageChange={({ page, pageLength }) => {
                      setFilter({
                        ...filter,
                        pageIndex: page - 1,
                        pageSize: pageLength,
                      });
                    }}
                    prevPageRenderer={() => <i className="fa fa-angle-left" />}
                    nextPageRenderer={() => <i className="fa fa-angle-right" />}
                  />
                </Flex>
              </>
            )}
          </CardBody>
        </Card>
      </Flex>
      {isOpenCreate && (
        <CreateMatchDialog
          isOpen={isOpenCreate}
          onOpen={onOpenCreate}
          onClose={onCloseCreate}
          fetchData={refetch}
        />
      )}
    </>
  );
}

export default TrackingShare;
