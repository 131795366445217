// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
// Custom components
import useAxios from "axios-hooks";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Row from "./Row";
import { Fragment, useEffect, useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";
import groupBy from "lodash/groupBy";
import { API_ROUTES, ROOT_API } from "utils/constant";
import { TablePagination } from "@trendmicro/react-paginations";
import DatePicker from "components/DatePicker/DatePicker";
import Loading from "components/Layout/Loading";
import { downloadFile } from "utils/helpers";
import moment from "moment-timezone";
import { Select } from "chakra-react-select";

const InitFilter = {
  pageSize: 10,
  pageIndex: 0,
};

export default function TrackingChat({ setIsViewUser }) {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const bgColor = useColorModeValue("blue.200", "white");
  const toast = useToast();
  const [filter, setFilter] = useState(InitFilter);
  const [listCommentator, setListCommentator] = useState([]);

  const [{ data: trackingChatData, loading }, getTrackingChatApi] = useAxios(
    {
      params: filter,
      url: ROOT_API + API_ROUTES.TRACKING_CHAT,
    },
    {
      manual: true,
      useCache: false,
      autoCancel: false,
    }
  );
  const [{ loading: exportLoading }, exportTrackingChatApi] = useAxios(
    {
      method: "get",
      url: ROOT_API + API_ROUTES.TRACKING_CHAT,
      responseType: "arraybuffer",
    },
    { manual: true }
  );
  const [{ loading: exportMessageLoading }, exportMessageApi] = useAxios(
    {
      method: "get",
      url: ROOT_API + API_ROUTES.EXPORT_MESSAGE,
      responseType: "arraybuffer",
    },
    { manual: true }
  );
  const [{ data:listCommentatorData }] = useAxios(
    {
      url: ROOT_API + API_ROUTES.GET_COMMENTATOR,
      params: filter,
    },
    {
      useCache: false,
    }
  );

  const optionsListCommentator = useMemo(() => {
    return listCommentatorData?.data?.map((itemCommentator) => ({
      value: itemCommentator.username,
      label: itemCommentator.name,
    })) || [];
  }, [listCommentatorData]);

  const groupData = useMemo(
    () => groupBy(trackingChatData?.data, (item) => item.slug),
    [trackingChatData?.data]
  );

  useEffect(() => {
    getTrackingChatApi();
  }, []);

  const onChangeDate = (type) => (date) => {
    setFilter((prev) => ({
      ...prev,
      ...(type === "startDate" && { endDate: null }),
      [type]: date,
    }));
  };

  const resetSearchKeywords = () => {
    setFilter(InitFilter);
    getTrackingChatApi({ params: { ...InitFilter } });
  };

  const handleSearch = () => {
    getTrackingChatApi({ params: { ...filter } });
  };

  const onExportTracking = () => {
    exportTrackingChatApi({ params: { isExport: "true", ...filter } })
      .then((response) => {
        downloadFile(response?.data, "tracking-chat");
        toast({
          title: "Export tracking chat successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title:
            error?.response?.data?.errors?.errors?.[0]?.msg ||
            error?.response?.data?.msg ||
            "Export tracking chat fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const onExportMessage = () => {
    exportMessageApi({ params: { isExport: "true", ...filter } })
      .then((response) => {
        downloadFile(response?.data, "tracking-message");
        toast({
          title: "Export tracking message successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title:
            error?.response?.data?.errors?.errors?.[0]?.msg ||
            error?.response?.data?.msg ||
            "Export tracking message fail",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex direction="column" pt={"24px"}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Flex alignItems={"center"} gap={5}>
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Tracking Chat
            </Text>
            <Button
              onClick={() => {
                setIsViewUser(true);
              }}
            >
              Switch to Tracking User
            </Button>
          </Flex>
          <FormControl mt={"16px"}>
            <Flex alignItems="end" justifyContent="space-between">
              <Flex
                flexWrap="wrap"
                alignItems={"end"}
                marginTop={4}
                gap={"20px"}
              >
                <FormControl
                  display="flex"
                  flexDirection={"column"}
                  width={{ base: "full", sm: "250px" }}
                  maxW="250px"
                >
                  <FormLabel
                    m="0"
                    fontSize="sm"
                    color={textColor}
                    fontWeight="bold"
                  >
                    Start Date
                  </FormLabel>
                  <DatePicker
                    selectedDate={filter.startDate}
                    onChange={(date) => onChangeDate("startDate")(date)}
                  />
                </FormControl>
                <FormControl
                  display="flex"
                  flexDirection={"column"}
                  width={{ base: "full", sm: "250px" }}
                  maxW="250px"
                >
                  <FormLabel
                    m="0"
                    fontSize="sm"
                    color={textColor}
                    fontWeight="bold"
                  >
                    End Date
                  </FormLabel>
                  <DatePicker
                    selectedDate={filter.endDate}
                    minDate={filter.startDate}
                    onChange={(date) => onChangeDate("endDate")(date)}
                  />
                </FormControl>
                <FormControl
                  display="flex"
                  flexDirection={"column"}
                  width={{ base: "full", sm: "250px" }}
                  maxW="250px"
                >
                  <FormLabel
                    m="0"
                    fontSize="sm"
                    color={textColor}
                    fontWeight="bold"
                  >
                    BLV
                  </FormLabel>
                  <Select
                    isClearable
                    placeholder="Choose"
                    menuShouldBlockScroll
                    value={listCommentator || null}
                    options={optionsListCommentator}
                    onChange={(e) => {
                      setListCommentator(e);
                      setFilter((prev) => ({
                        ...prev,
                        commentator: e ? e.value : null,
                      }));
                    }}
                    onMenuClose={() => {
                      if (!listCommentator) {
                        setListCommentator(null);
                      }
                    }}
                  />
                </FormControl>
                <Button colorScheme="blue" onClick={handleSearch}>
                  Search
                </Button>
                <Button onClick={resetSearchKeywords}>Reset</Button>
              </Flex>
              <Flex gap="14px">
                <Button
                  colorScheme="blue"
                  isLoading={exportMessageLoading}
                  onClick={onExportMessage}
                >
                  Export Message
                </Button>
                <Button
                  colorScheme="blue"
                  isLoading={exportLoading}
                  onClick={onExportTracking}
                >
                  Export
                </Button>
              </Flex>
            </Flex>
          </FormControl>
        </CardHeader>
        <CardBody>
          <Stack overflow={"auto"}>
            <Table variant="simple" color={textColor}>
              <Thead bg={bgColor} hidden>
                <Tr my=".8rem" pl="0px" color={textColor}>
                  <Th borderColor={borderColor} color={textColor}>
                    League
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {loading && (
                  <Stack minH="100px" justifyContent="center">
                    <Loading />
                  </Stack>
                )}

                {!loading &&
                  !isEmpty(groupData) &&
                  Object.keys(groupData)?.map((key, index) => (
                    <Fragment key={index}>
                      <Tr>
                        <Td
                          borderColor={borderColor}
                          borderBottom={
                            index === groupData?.[key].length - 1
                              ? "none"
                              : null
                          }
                          // colSpan={6}
                          bg={borderColor}
                        >
                          <Text
                            fontSize="md"
                            color={textColor}
                            fontWeight="bold"
                          >
                            Slug URL: {key}{" "}
                            {`- Time: ${moment
                              .unix(groupData[key]?.at(0)?.timestamp)
                              .format("DD-MM-YYYY HH:mm")}`}
                          </Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td px={0} py={0}>
                          <Table>
                            <Thead>
                              <Tr
                                py="1rem"
                                pl="0px"
                                color={textColor}
                                bg={bgColor}
                              >
                                <Th borderColor={borderColor} color={textColor}>
                                  Commentator
                                </Th>
                                <Th borderColor={borderColor} color={textColor}>
                                  Number of chat users
                                </Th>
                                <Th borderColor={borderColor} color={textColor}>
                                  Action
                                </Th>
                              </Tr>
                            </Thead>
                            {groupData?.[key]?.map(
                              (row_child, _index, _arr) => (
                                <Tbody
                                  key={`chat-${row_child._id?.matchId}-${_index}`}
                                >
                                  <Row
                                    rowDetail={row_child}
                                    isLast={
                                      _index === _arr.length - 1 ? true : false
                                    }
                                  />
                                </Tbody>
                              )
                            )}
                          </Table>
                        </Td>
                      </Tr>
                    </Fragment>
                  ))}

                {!loading && isEmpty(groupData) && (
                  <Tr>
                    <Td colSpan={5} height="150px" textAlign="center">
                      No data
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Stack>
          <Flex justifyContent={"flex-end"}>
            <TablePagination
              type="full"
              page={trackingChatData?.pagination?.page}
              pageLength={trackingChatData?.pagination?.pageSize}
              totalRecords={trackingChatData?.pagination?.count}
              onPageChange={({ page, pageLength }) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
                getTrackingChatApi({
                  params: {
                    ...filter,
                    pageSize: pageLength,
                    pageIndex: page - 1,
                  },
                });
              }}
              prevPageRenderer={() => <i className="fa fa-angle-left" />}
              nextPageRenderer={() => <i className="fa fa-angle-right" />}
            />
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}
