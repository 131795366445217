import React, { useState, useEffect, useCallback } from "react";

import {
  Select,
  Button,
  Input,
  FormLabel,
  FormControl,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  useColorModeValue,
  Flex,
  Text,
  Box,
} from "@chakra-ui/react";

import { ROOT_API, API_ROUTES } from "utils/constant";
import { CopyIcon } from "@chakra-ui/icons";
import useAxios from "axios-hooks";
import moment from "moment";
const InitFilter = {
  pageSize: 10000000,
  pageIndex: 0,
};

const ChatHistories = (props) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const textColor = useColorModeValue("gray.500", "white");
  const [filter, setFilter] = useState(InitFilter);

  const { isOpen, onOpen, onClose, matchSlug, commentatorUsername } = props;
  const [{ data, loading }, refetch] = useAxios(
    {
      params: {
        ...filter,
        commentatorUsername,
      },
      url: ROOT_API + API_ROUTES.GET_MESSAGES + matchSlug,
    },
    {
      manual: true,
      useCache: false,
      autoCancel: false,
    }
  );
  useEffect(() => {
    refetch();
  }, []);

  const renderChatMessage = () => {
    return data?.data?.map((i) => (
      <>
        <Flex
          flex
          flexDirection={"column"}
          borderStyle={"dot"}
          borderWidth={0.5}
          px={2}
        >
          <Flex
            flex
            flexDirection={"row"}
            alignItems={"flex-start"}
            color={i?.deletedAt ? "red" : "#1A202C"}
          >
            <Flex flex={1} flexDirection={"column"}>
              <Text fontSize={12} fontWeight={600}>
                {i?.member?.fullname ||
                  i?.member?.username ||
                  i.commentator?.nickname || i?.commentator?.username}
              </Text>
              <Text fontSize={10} fontStyle={"italic"}>
                {i?.createdAt
                  ? moment(i?.createdAt).local().format("YYYY-MM-DD HH:mm")
                  : ""}
              </Text>
            </Flex>
            <Flex flex={5}>
              <Text
                fontSize={14}
                dangerouslySetInnerHTML={{
                  __html: i?.content || "",
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </>
    ));
  }

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size="4xl"
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            {`Lịch Sử Chat Trận Đấu: ${matchSlug}`}
            {commentatorUsername && (
              <Text>{`BLV: ${commentatorUsername}`}</Text>
            )}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody maxH={500} overflow="auto">
            <Flex flexDirection="column" rowGap={2}>
              {renderChatMessage()}
            </Flex>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ChatHistories;
