// import
import React from "react";
import { FaGift, FaUserGroup, FaUserPlus } from "react-icons/fa6";
import { FaUser, FaImage, FaHistory, FaShareAlt } from "react-icons/fa";
import { IoFootball } from "react-icons/io5";
import { AiFillSound } from "react-icons/ai";
import { IoMdSettings } from "react-icons/io";
import { MdArticle, MdContentPaste, MdOutlinePostAdd, MdOutlineSettings, MdPermMedia } from "react-icons/md";
import Dashboard from "views/Dashboard/Dashboard.js";
import Profile from "views/Dashboard/Profile.js";
import SignIn from "views/Pages/SignIn.js";
import User from "views/Dashboard/User/User";
import Member from "views/Dashboard/Member/Member";
import CDNConfig from "views/Dashboard/CDN/CDNConfig";
import Content from "views/Dashboard/Content/Content";

import { HomeIcon, StatsIcon, PersonIcon } from "components/Icons/Icons";
import { Roles } from "utils/constant";
import Kol from "views/Dashboard/Kol/Kol";
import League from "views/Dashboard/League/League";
import Team from "views/Dashboard/Team/Team";
import SelectedMatches from "views/Dashboard/SelectedMatches/SelectedMatches";
import Group from "views/Dashboard/Group/Group";
import MiniLeague from "views/Dashboard/MiniLeague";
import MiniLeagueDetail from "views/Dashboard/MiniLeagueDetail";
import Season from "views/Dashboard/Season";
import GroupDetail from "views/Dashboard/GroupDetail";
import SeasonMatches from "views/Dashboard/SeasonMatches";
import Posts from "views/Dashboard/Posts";
import CreatePost from "views/Dashboard/Posts/components/CreatePost";
import BannerAds from "views/Dashboard/BannerAds";
import MatchesCDN from "views/Dashboard/MatchCDN/Matches";
import TrackingHistory from "views/Dashboard/TrackingHistory";
import CandidateCommentator from "views/Dashboard/CandidateCommentator";
import PinMessage from "views/Dashboard/PinMessage";
import PointSetting from "views/Dashboard/PointSetting";
import Media from "views/Dashboard/Media/Media";
import { GoTasklist } from "react-icons/go";
import Missions from "views/Dashboard/Missions";
import CreateMissionDialog from "views/Dashboard/Missions/components/CreateMission";
import MissionBonus from "views/Dashboard/MissionBonus";
import CreateMissionBonus from "views/Dashboard/MissionBonus/components/CreateMissionBonus";
import { BsLayoutTextSidebarReverse } from "react-icons/bs";
import HistoryMission from "views/Dashboard/HistoryMission";
import TrackingShare from "views/Dashboard/TrackingShare/TrackingShare";
import Config from "views/Dashboard/ConfigPage";
import KolV2 from "views/Dashboard/KolV2/KolV2";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
    role: [Roles.ADMIN],
  },
  {
    path: "/pin-message",
    name: "Pin Message",
    icon: <HomeIcon color="inherit" />,
    component: PinMessage,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.CUSTOMER_CARE],
  },
  {
    path: "/groups",
    name: "Group User",
    icon: <FaUserGroup color="inherit" />,
    component: Group,
    layout: "/admin",
    role: [Roles.ADMIN],
  },
  {
    path: "/member",
    name: "Member",
    icon: <FaUser color="inherit" />,
    component: Member,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.MANAGER],
  },
  {
    path: "/mission-member/:id",
    name: "Mission member",
    icon: <BsLayoutTextSidebarReverse color="inherit" />,
    component: HistoryMission,
    layout: "/admin",
    role: [Roles.ADMIN],
    redirect: true,
  },
  {
    path: "/user",
    name: "User",
    icon: <FaUser color="inherit" />,
    component: User,
    layout: "/admin",
    role: [Roles.ADMIN],
  },
  // {
  //   path: "/Link",
  //   name: "Link",
  //   icon: <StatsIcon color="inherit" />,
  //   component: Link,
  //   layout: "/admin",
  //   role: [Roles.ADMIN],
  // },
  // {
  //   path: "/request-link",
  //   name: "Request Links",
  //   rtlName: "لوحة القيادة",
  //   icon: <StatsIcon color="inherit" />,
  //   component: RequestLink,
  //   layout: "/admin",
  //   role: [Roles.ADMIN],
  // },
  // {
  //   path: "/devices",
  //   name: "Devices",
  //   rtlName: "لوحة القيادة",
  //   icon: <StatsIcon color="inherit" />,
  //   component: Devices,
  //   layout: "/admin",
  //   role: [Roles.ADMIN],
  // },
  // {
  //   path: "/country-codes",
  //   name: "Countries",
  //   rtlName: "لوحة القيادة",
  //   icon: <StatsIcon color="inherit" />,
  //   component: CountryCode,
  //   layout: "/admin",
  //   role: [Roles.ADMIN, Roles.USER],
  // },
  {
    path: "/leagues",
    name: "Leagues",
    rtlName: "لوحة القيادة",
    icon: <IoFootball color="inherit" />,
    component: League,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.USER, Roles.MANAGER],
  },
  {
    path: "/mini-leagues/:id",
    name: "Mini Leagues",
    rtlName: "لوحة القيادة",
    icon: <IoFootball color="inherit" />,
    component: MiniLeagueDetail,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.USER],
    redirect: true,
  },
  {
    path: "/mini-leagues",
    name: "Mini Leagues",
    rtlName: "لوحة القيادة",
    icon: <IoFootball color="inherit" />,
    component: MiniLeague,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.USER],
  },
  {
    path: "/teams",
    name: "Teams",
    rtlName: "لوحة القيادة",
    icon: <FaUserPlus color="inherit" />,
    component: Team,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.USER],
  },
  {
    path: "/season/:id/group/:groupId",
    name: "Group Detail",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: GroupDetail,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.USER],
    redirect: true,
  },
  {
    path: "/season/:id/matches",
    name: "Season Matches",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: SeasonMatches,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.USER],
    redirect: true,
  },
  {
    path: "/season/:id",
    name: "Season",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: Season,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.USER],
    redirect: true,
  },
  // {
  //   path: "/add-link",
  //   name: "My Links",
  //   rtlName: "لوحة القيادة",
  //   icon: <StatsIcon color="inherit" />,
  //   component: MemberLink,
  //   layout: "/admin",
  // },
  {
    path: "/matches",
    name: "Matches",
    icon: <IoFootball color="inherit" />,
    component: MatchesCDN,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.USER, Roles.MANAGER],
  },
  {
    path: "/selected-matches",
    name: "Livestream Matches",
    icon: <IoFootball color="inherit" />,
    component: SelectedMatches,
    layout: "/admin",
    role: [Roles.ADMIN],
  },
  {
    path: "/kols",
    name: "Commentator",
    icon: <AiFillSound color="inherit" />,
    component: Kol,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.USER, Roles.MANAGER],
  },
  {
    path: "/kol",
    name: "Kol",
    icon: <MdArticle color="inherit" />,
    component: KolV2,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.MANAGER, Roles.GUIDE],
  },
  {
    path: "/cv-kols",
    name: "CV",
    icon: <AiFillSound color="inherit" />,
    component: CandidateCommentator,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.USER, Roles.MANAGER],
  },
  {
    path: "/config-cdn",
    name: "CDN",
    icon: <IoMdSettings color="inherit" />,
    component: CDNConfig,
    layout: "/admin",
    role: [Roles.ADMIN],
  },
  {
    path: "/contents",
    name: "Content",
    icon: <MdContentPaste color="inherit" />,
    component: Content,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.MANAGER, Roles.GUIDE],
  },
  {
    path: "/media",
    name: "Media",
    icon: <MdPermMedia color="inherit" />,
    component: Media,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.MANAGER, Roles.GUIDE],
  },
  {
    path: "/posts/detail/:id",
    name: "Post detail",
    icon: <StatsIcon color="inherit" />,
    component: CreatePost,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.MANAGER, Roles.GUIDE],
    redirect: true,
  },
  {
    path: "/posts/create",
    name: "Create post",
    icon: <StatsIcon color="inherit" />,
    component: CreatePost,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.MANAGER, Roles.GUIDE],
    redirect: true,
  },
  {
    path: "/posts",
    name: "Posts",
    icon: <MdOutlinePostAdd color="inherit" />,
    component: Posts,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.MANAGER, Roles.GUIDE],
  },
  {
    path: "/banner-ads",
    name: "Setting",
    icon: <FaImage color="inherit" />,
    component: BannerAds,
    layout: "/admin",
    role: [Roles.ADMIN],
  },
  {
    path: "/tracking",
    name: "Tracking history",
    icon: <FaHistory color="inherit" />,
    component: TrackingHistory,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.MANAGER],
  },
  // {
  //   path: "/bookmakers",
  //   name: "Bookmakers",
  //   icon: <IoDiamondOutline color="inherit" />,
  //   component: Bookmakers,
  //   layout: "/admin",
  //   role: [Roles.ADMIN, Roles.MANAGER],
  // },
  {
    path: "/point-setting",
    name: "Point setting",
    icon: <IoMdSettings color="inherit" />,
    component: PointSetting,
    layout: "/admin",
    role: [Roles.ADMIN],
  },
  {
    path: "/missions",
    name: "Mission",
    icon: <GoTasklist color="inherit" />,
    component: Missions,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.MANAGER, Roles.GUIDE],
  },
  {
    path: "/mission/create/:id",
    name: "Edit Mission",
    icon: <StatsIcon color="inherit" />,
    component: CreateMissionDialog,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.MANAGER, Roles.GUIDE],
    redirect: true,
  },
  {
    path: "/mission/create",
    name: "Create Mission",
    icon: <StatsIcon color="inherit" />,
    component: CreateMissionDialog,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.MANAGER, Roles.GUIDE],
    redirect: true,
  },
  {
    path: "/mission-bonus",
    name: "Mission Bonus",
    icon: <FaGift color="inherit" />,
    component: MissionBonus,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.MANAGER, Roles.GUIDE],
  },
  {
    path: "/bonus/create/:id",
    name: "Edit Mission Bonus",
    icon: <StatsIcon color="inherit" />,
    component: CreateMissionBonus,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.MANAGER, Roles.GUIDE],
    redirect: true,
  },
  {
    path: "/bonus/create",
    name: "Create Mission Bonus",
    icon: <StatsIcon color="inherit" />,
    component: CreateMissionBonus,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.MANAGER, Roles.GUIDE],
    redirect: true,
  },

  //
  {
    path: "/configs",
    name: "Configs",
    icon: <MdOutlineSettings color="inherit" />,
    component: Config,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.MANAGER, Roles.GUIDE],
  },
  //
  {
    path: "/missions-member",
    name: "History mission",
    icon: <BsLayoutTextSidebarReverse color="inherit" />,
    component: HistoryMission,
    layout: "/admin",
    role: [Roles.ADMIN],
  },
  {
    path: "/tracking-share",
    name: "Tracking Share",
    icon: <FaShareAlt color="inherit" />,
    component: TrackingShare,
    layout: "/admin",
    role: [Roles.ADMIN, Roles.CUSTOMER_CARE, Roles.MANAGER],
  },
  {
    name: "ACCOUNT PAGES",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    views: [
      {
        path: "/profile",
        name: "Profile",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
        role: [
          Roles.ADMIN,
          Roles.USER,
          Roles.GUEST,
          Roles.COMMENTATOR,
          Roles.MANAGER,
          Roles.CUSTOMER_CARE,
        ],
      },
      {
        path: "/signin",
        component: SignIn,
        layout: "/auth",
      },
      // {
      //   path: "/signup",
      //   name: "Sign Up",
      //   rtlName: "لوحة القيادة",
      //   icon: <RocketIcon color='inherit' />,
      //   component: SignUp,
      //   layout: "/auth",
      // },
    ],
  },
];
export default dashRoutes;
