import { ChatIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Badge,
  Button,
  Flex,
  IconButton,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import ChatHistories from "./ChatHistories";

function Row(props) {
  const { rowDetail, isLast } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const {
    isOpen: isOpenDetailCommentator,
    onOpen: onOpenDetailCommentator,
    onClose: onCloseDetailCommentator,
  } = useDisclosure();

  return (
    <>
      <Tr>
        <Td
          minWidth={{ sm: "250px" }}
          borderColor={borderColor}
          borderBottom={isLast ? "none" : null}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Flex direction="column">
              <Text
                fontSize="md"
                color={titleColor}
                fontWeight="bold"
                minWidth="100%"
              >
                {rowDetail?._id?.commentator}
              </Text>
            </Flex>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {rowDetail?.qty_chat}
            </Text>
          </Flex>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Flex direction="column">
            <IconButton variant="ghost" onClick={onOpenDetailCommentator} width={50}>
              <ChatIcon />
            </IconButton>
          </Flex>
        </Td>
      </Tr>
      {isOpenDetailCommentator && (
        <ChatHistories
          isOpen={isOpenDetailCommentator}
          onOpen={onOpenDetailCommentator}
          onClose={onCloseDetailCommentator}
          commentatorUsername={rowDetail?._id?.commentator}
          matchSlug={rowDetail?.slug}
        />
      )}
    </>
  );
}

export default Row;
